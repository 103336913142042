import { FirebaseApp } from '@shared/firebase-client';
import axios from 'axios';

import { config } from './config';

export enum FunctionName {
  CREATE_LEAD = 'createLead',
  FETCH_PRODUCTS = 'fetchProducts',
  CREATE_PAYMENT = 'createPayment',
}

export const firebaseApp = new FirebaseApp(
  {
    apiKey: config.FIREBASE.API_KEY,
    appId: config.FIREBASE.APP_ID,
    projectId: config.FIREBASE.PROJECT_ID,
    name: config.NAME,
  },
  { disableAuthEmulator: true, disableFunctionsEmulator: true },
);

export const APIClient = axios.create();

APIClient.interceptors.response.use((req) => req.data);

export const getAPIUrl = (fnName: FunctionName) =>
  (config.IS_DEV
    ? `http://127.0.0.1:4002/harmonia-staging/us-central1/${fnName}`
    : `https://${fnName.toLocaleLowerCase()}-${config.FIREBASE.HASH}-uc.a.run.app/`);
