import { inputAnatomy } from '@chakra-ui/anatomy';
import { extendTheme, createMultiStyleConfigHelpers } from '@chakra-ui/react';
import localFont from 'next/font/local';

const switzerFont = localFont({
  src: [
    {
      path: 'assets/fonts/switzer-light.woff2',
      weight: '300',
    },
    {
      path: 'assets/fonts/switzer-regular.woff2',
      weight: '400',
    },
    {
      path: 'assets/fonts/switzer-semibold.woff2',
      weight: '500',
    },
    {
      path: 'assets/fonts/switzer-semibold.woff2',
      weight: '600',
    },
  ],
  display: 'swap',
  variable: '--switzerFont',
});

export const fonts = {
  switzer: switzerFont,
};

export const colors = {
  primary: '#EA610C',
  primaryLight: '#FDF5EB',
  secondary: 'blue',
  error: '#E90909',
  green: '#17BA13',
  blue: '#4277FF',
  black: '#222222',
  black90: '#222222e6',
  black60: '#22222299',
  black50: '#22222280',
  black40: '#22222266',
  black10: '#2222221a',
};

const textStyles = {
  quizQuestion: {
    fontFamily: 'var(--chakra-fonts-heading)',
    fontSize: ['1.75rem', '2.5rem'], // 28px / 40px
    lineHeight: '100%',
    textAlign: ['inherit', 'center'],
    marginBottom: ['1rem', '2rem'],
    marginX: 'auto',
    maxWidth: '44rem',
  },
  h4: {
    fontFamily: 'var(--chakra-fonts-heading)',
    fontSize: '1.375rem', // 22px
    lineHeight: '100%',
  },
};

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

export const theme = extendTheme({
  fonts: {
    body: `${switzerFont.variable}, sans-serif`,
    heading: `'Orelega One', sans-serif`,
  },
  styles: {
    global: {
      a: {
        flex: 1,
        display: 'flex',
      },
      ul: {
        listStylePosition: 'inside',
      },
      p: {
        lineHeight: '130%',
      },
    },
  },
  colors,
  textStyles,
  components: {
    Button: {
      baseStyle: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        gap: '0.625rem',
        cursor: 'pointer',
        border: 'none',
        boxShadow: '0px 1px 3px 0px #00000012, 0px 5px 12px 0px #0000001A',
        color: 'white',
        borderRadius: '32px',
        fontWeight: 500,
        fontFamily: 'inherit',
        fontSize: '1rem',
        paddingX: '1rem',
        _hover: {
          _disabled: {
            background: 'black',
          },
        },
      },
      sizes: {
        default: {
          height: '53px',
        },
      },
      variants: {
        primary: {
          bg: 'primary',
          _hover: {
            _disabled: {
              background: 'primary',
            },
          },
        },
        black: {
          bg: 'black',
        },
      },
      defaultProps: {
        variant: 'primary',
        size: 'default',
      },
    },
    Input: defineMultiStyleConfig({
      baseStyle: definePartsStyle({
        field: {
          width: '100%',
          height: '3rem',
          padding: '0.875rem 1rem',
          border: '1.5px solid',
        },
      }),
      variants: {
        default: definePartsStyle({
          field: {
            borderColor: 'black',
          },
        }),
      },
      defaultProps: {
        variant: 'default',
      },
    }),
  },
});
