const { PLASMIC_PROJECT_ID, PLASMIC_TOKEN, GTM_TOKEN, NEXT_PUBLIC_STRIPE_API_KEY, META_PIXEL, HOTJAR_ID } = process.env;

export const config = {
  NAME: 'DrinkHarmonia',
  DOMAIN: 'drinkharmonia.com',
  EMAIL: 'hello@drinkharmonia.com',
  TITLE: 'Release stress & lose weight with Cortisol Coctail',
  DESCRIPTION: 'Release stress & lose weight with Cortisol Coctail',
  IS_DEV: process.env.NODE_ENV === 'development',
  PLASMIC: {
    PROJECT_ID: PLASMIC_PROJECT_ID as string,
    PROJECT_TOKEN: PLASMIC_TOKEN as string,
  },
  FIREBASE: {
    API_KEY: process.env.NEXT_PUBLIC_FIREBASE_API_KEY as string,
    PROJECT_ID: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID as string,
    APP_ID: process.env.NEXT_PUBLIC_FIREBASE_APP_ID as string,
    HASH: process.env.NEXT_PUBLIC_FIREBASE_HASH as string,
  },
  STRIPE: {
    API_KEY: NEXT_PUBLIC_STRIPE_API_KEY as string,
  },
  SNIPPETS: {
    GTM: GTM_TOKEN,
    META_PIXEL,
    HOTJAR_ID,
  },
};
